import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: {
      "padding": "4px"
    },
    class: "markdown-body",
    innerHTML: _ctx.content
  }, null, 8, _hoisted_1);
}