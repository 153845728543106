import { defineComponent } from 'vue';
import { useMarkdown } from '../index';
export default defineComponent({
  name: 'App',
  emits: ['loaded', 'showImg', 'titleMenu'],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var content = useMarkdown(props, emit);
    return {
      content: content
    };
  }
});